<template>
  <div id="paypal-container"></div>
</template>

<script>
import { mapGetters } from "vuex";
import { loadScript } from "@paypal/paypal-js";
export default {
  name: "Paypal",
  props: {
    user: {
      type: Object,
      defaule: () => {}
    },
    address: {
      type: Object,
      defaule: () => {}
    }
  },
  data() {
    return {
      accessToken: "",
      paypalResponses: {}
    };
  },
  computed: {
    ...mapGetters(["bookingParams"]),
    product() {
      return this.$store.getters.getSelectedProduct;
    }
  },
  methods: {
    paymentSuccessful() {
      this.$emit("payment", { success: true });
    },
    async createPaypalForm() {
      const self = this;

      await loadScript({
        "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
        // commit: true,
        // debug: true,
        locale: "en_US",
        currency: "GBP"
      }).then(paypal => {
        paypal
          .Buttons({
            style: {
              layout: "vertical",
              color: "gold",
              shape: "pill",
              label: "pay"
            },
            createOrder: (data, actions) => {
              return actions.order.create({
                intent: "CAPTURE",
                // links: [
                //   {
                //     link_description: {
                //       href: "/",
                //       rel: "nofollow"
                //     }
                //   }
                // ],
                purchase_units: [
                  {
                    items: [
                      {
                        name: `${this.bookingParams.airportName} ${this.bookingParams.roomName}`,
                        description: this.product.Name || "TEST",
                        quantity: "1",
                        unit_amount: {
                          currency_code: "GBP",
                          value: this.product.Price || this.product.TotalPrice
                        }
                      }
                    ],
                    amount: {
                      currency_code: "GBP",
                      value: this.product.Price || this.product.TotalPrice,
                      breakdown: {
                        item_total: {
                          currency_code: "GBP",
                          value: this.product.Price || this.product.TotalPrice
                        }
                      }
                    }
                  }
                ],
                application_context: {
                  return_url: "https://hotels24seven.com",
                  cancel_url: "https://hotels24seven.com/booknow",
                  shipping_preference: "NO_SHIPPING",
                  user_action: "PAY_NOW"
                },
                payer: {
                  email_address: self.user.email,
                  name: {
                    given_name: self.user.firstname,
                    surname: self.user.surname
                  },
                  address: {
                    address_line_1: self.address.address1,
                    address_line_2: self.address.address2,
                    postal_code: self.address.postcode,
                    country_code: self.address.country
                  }
                }
              });
            },
            onApprove: function(data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(details => {
                // This function shows a transaction success message to your buyer.
                console.log("onApprove", details);
                self.paymentSuccessful();
              });
            },
            onError: err => {
              self.paymentSuccessful(false, err);
            }
          })
          .render("#paypal-container");
      });
    }
  },
  async mounted() {
    // await this.generateAccessToken();
    // await this.generateClientToken();
    // await this.createOrder();

    await this.createPaypalForm();

    /* moved this once the payment is working properly */
    // this.paymentSuccessful();
  }
};
</script>

<style scoped>
#paypal-container > div {
  display: block;
  margin: auto;
}
</style>
