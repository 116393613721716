<template>
  <div class="content">
    <div class="steps booknow">
      <div v-if="showError">
        <h1
          style="margin: 5rem auto;display: block;text-align: center;color: red;text-shadow: -1px 1px rgb(255 255 255 / 20%); font-size: 3rem;"
        >
          Something went wrong.<br />Please contact hotels24seven.
        </h1>
      </div>
      <div v-else-if="showBookingComplete" class="booking-complete">
        <h1
          style="margin: 5rem auto;display: block;text-align: center;color: #ffffff;text-shadow: -1px 1px rgb(255 255 255 / 20%); font-size: 3rem;"
        >
          Booking complete
        </h1>

        <div>
          <p>
            Your reference number is
            <strong>{{ bookingRef }}</strong>
            .
          </p>

          <p>
            We have sent a confirmation email to {{ user.email }}. Please double
            check that all the details are correct.
          </p>

          <p>
            If you have any queries or need to change your booking, please call
            us on 08000832808.
          </p>

          <a href="/" class="button book">MAKE ANOTHER BOOKING</a>

          <p>Thank you for using Hotels24Seven.</p>
        </div>
      </div>
      <form :class="{ hidden: !showUserData || showBookingComplete }">
        <fieldset>
          <div class="row">
            <h2>USER INFO</h2>
            <div class="input-wrapper">
              <select
                v-model="user.title"
                name="title"
                :class="bookingParams.airportName.toLowerCase()"
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
              </select>
              <i class="dropdown"></i>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.firstname,
                  [bookingParams.airportName.toLowerCase()]: true
                }"
                v-model="user.firstname"
                name="firstname"
                placeholder="First Name"
                required
              />
              <span v-show="errors && errors.firstname" class="help is-danger">
                {{ errors.firstname }}
              </span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.surname,
                  [bookingParams.airportName.toLowerCase()]: true
                }"
                name="surname"
                v-model="user.surname"
                placeholder="Surname"
              />
              <span v-show="errors && errors.surname" class="help is-danger">{{
                errors.surname
              }}</span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.phone,
                  [bookingParams.airportName.toLowerCase()]: true
                }"
                name="phone"
                v-model="user.phone"
                placeholder="Phone"
              />
              <span v-show="errors && errors.phone" class="help is-danger">{{
                errors.phone
              }}</span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.email,
                  [bookingParams.airportName.toLowerCase()]: true
                }"
                name="email"
                v-model="user.email"
                placeholder="Email Address"
              />
              <span v-show="errors && errors.email" class="help is-danger">{{
                errors.email
              }}</span>
            </div>
          </div>

          <div class="form-buttons">
            <button class="button book" @click.prevent="validateUser">
              NEXT
            </button>
          </div>

          <p class="small">
            We will only use your email address to confirm your booking. All
            details you provide are protected by our privacy policy.
          </p>
        </fieldset>
      </form>
      <form :class="{ hidden: !showAddressData || showBookingComplete }">
        <fieldset>
          <h2>ADDRESS</h2>
          <input
            :class="{
              input: true,
              'is-danger': errors && errors.addressline1,
              [bookingParams.airportName.toLowerCase()]: true
            }"
            v-model="address.address1"
            name="addressline1"
            placeholder="Address Line 1"
            required
          />
          <span v-show="errors && errors.addressline1" class="help is-danger">{{
            errors && errors.addressline1
          }}</span>

          <input
            v-model="address.address2"
            name="addressline2"
            placeholder="Address Line 2"
            :class="bookingParams.airportName.toLowerCase()"
          />

          <input
            :class="{
              input: true,
              'is-danger': errors && errors.town,
              [bookingParams.airportName.toLowerCase()]: true
            }"
            name="town"
            v-model="address.town"
            placeholder="Town or City"
          />
          <span v-show="errors && errors.town" class="help is-danger">{{
            errors && errors.town
          }}</span>

          <input
            name="county"
            v-model="address.county"
            placeholder="County / State"
            :class="{
              [bookingParams.airportName.toLowerCase()]: true
            }"
          />
          <span v-show="errors && errors.county" class="help is-danger">{{
            errors && errors.county
          }}</span>

          <input
            :class="{
              input: true,
              'is-danger': errors && errors.postcode,
              [bookingParams.airportName.toLowerCase()]: true
            }"
            name="postcode"
            v-model="address.postcode"
            placeholder="Postcode / Zipcode"
          />
          <span v-show="errors && errors.postcode" class="help is-danger">{{
            errors && errors.postcode
          }}</span>

          <div class="input-wrapper">
            <select
              :class="{
                [bookingParams.airportName.toLowerCase()]: true
              }"
              name="country"
              v-model="address.country"
            >
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia, Plurinational State of</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">Congo, the Democratic Republic of the</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Côte d'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and McDonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK"
                >Macedonia, the former Yugoslav Republic of</option
              >
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthélemy</option>
              <option value="SH"
                >Saint Helena, Ascension and Tristan da Cunha</option
              >
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin (French part)</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten (Dutch part)</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS"
                >South Georgia and the South Sandwich Islands</option
              >
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela, Bolivarian Republic of</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.S.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
            <i class="dropdown dark"></i>
            <span v-show="errors && errors.country" class="help is-danger">{{
              errors && errors.country
            }}</span>
          </div>

          <div class="form-buttons">
            <button class="button book" @click.prevent="showUser">BACK</button>
            <button class="button book" @click.prevent="validateAddress">
              NEXT
            </button>
          </div>

          <p class="small">
            Your address is required to process your payment securely. Please
            use the address where your payment card is registered.
          </p>
        </fieldset>
      </form>

      <div
        v-if="!showBookingComplete && showPaymentForm && !showError"
        class="form-box"
      >
        <div class="form-inner-box">
          <payment-form
            class="paypal-form"
            :user="user"
            :address="address"
            @payment="bookInHX"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import { hxBooking, getProduct } from "@/api/holiday-extras/holiday-extra.js";
import Validate from "@/mixins/Validate";
import DateFilters from "@/mixins/DateFilters";

import paymentForm from "@/components/booking/forms/payment.vue";

export default {
  name: "BookNow",
  components: { paymentForm },
  mixins: [Validate, DateFilters],

  data() {
    return {
      showBookingComplete: false,
      showError: false,
      showUserData: true,
      showAddressData: false,
      showPaymentForm: false,
      user: this.dummydata("user"),
      address: this.dummydata("address"),
      errors: {},
      emailConfig: {
        serviceID: "gmail",
        publicKey: "user_nRu9aKYKWfR7pFTE7tFmg"
      },
      emailTemplates: {
        ERROR: "error",
        HX_FAILED: "error",
        PAYMENT_FAILED: "error",
        SUCCESS: "confirmation"
      },
      bookingDetails: {},
      productData: {}
    };
  },
  computed: {
    ...mapGetters(["bookingParams"]),
    selectedProduct() {
      return {
        ...this.$store.getters.getSelectedProduct
      };
    },
    bookingRef() {
      if (
        this.bookingDetails &&
        this.bookingDetails.Booking &&
        this.bookingDetails.Booking.BookingRef
      ) {
        return this.bookingDetails.Booking.BookingRef;
      }
      return "";
    }
  },
  methods: {
    showAddress() {
      this.showUserData = false;
      this.showPaymentForm = false;
      this.showAddressData = true;
    },
    showUser() {
      this.showUserData = true;
      this.showAddressData = false;
      this.showPaymentForm = false;
    },
    showPayment() {
      this.showUserData = false;
      this.showAddressData = false;
      this.showPaymentForm = true;
    },
    validateUser() {
      this.errors = this.formValidator({
        firstname: {
          value: this.user.firstname,
          validations: {
            required: "Firstname is required."
          }
        },
        surname: {
          value: this.user.surname,
          validations: {
            required: "Surname is required."
          }
        },
        phone: {
          value: this.user.phone,
          validations: {
            required: "Phone is required."
          }
        },
        email: {
          value: this.user.email,
          validations: {
            required: "Email is required.",
            email: "Please enter a valid email address"
          }
        }
      });

      if (this.is_empty(this.errors) === false) this.showAddress();
    },
    validateAddress() {
      this.errors = this.formValidator({
        address1: {
          value: this.address.address1,
          validations: {
            required: "Address line 1 is required."
          }
        },
        town: {
          value: this.address.town,
          validations: {
            required: "Town is required."
          }
        },
        county: {
          value: this.address.county,
          validations: {
            required: "County is required."
          }
        },
        postcode: {
          value: this.address.postcode,
          validations: {
            required: "Postcode is required."
          }
        },
        country: {
          value: this.address.country,
          validations: {
            required: "Country is required."
          }
        }
      });
      if (this.is_empty(this.errors) === false) {
        this.showPayment();
      }
    },
    bookInHX(payment = true, error = "") {
      if (payment) {
        const params = {
          Initials: this.$store.state.hxInfo.initials,
          ArrivalDate: this.$options.filters.getFormalDate(
            this.bookingParams.departDate
          ),

          /* confirm with dave the default data */
          /* lounge booking doesn't work without this */
          ArrivalTime: "12:00",
          DepartDate: this.$options.filters.getFormalDate(
            this.bookingParams.returnDate
          ),
          // DepartTime
          Title: this.user.title,
          Initial: this.user.firstname,
          Surname: this.user.surname,
          Address: this.address.address1,
          Town: this.address.town,
          County: this.address.county,
          PostCode: this.address.postcode,
          Email: this.user.email,
          Waiver: "N",
          PriceCheckFlag: "Y",
          PriceCheckPrice: "Y",

          ParkingDays: this.selectedProduct.ParkingDays,

          Nights: this.bookingParams.stayingDays,
          RoomCode: this.selectedProduct.RoomCode,
          Adults: this.bookingParams.adults,
          Children: this.bookingParams.children,

          DayPhone: ""
          // CarColour:
          // CarMake:
          // CarModel:
          // Registration:
          // ReturnFlight:
          // "Supplements[0][Code]":
          // "Supplements[0][Quantity]":
          // "Supplements[0][Date]":
          // "Supplements[0][TotalPrice]":
          // "Supplements[1][Code]":
          // "Supplements[1][Date]":
          // "Supplements[1][Adults]":
          // "Supplements[1][Children]":
          // "Supplements[1][TotalPrice]":
        };

        /* currently 1 room per booking */
        // if (this.secondRoomType !== "") {
        //   params.SecondRoomType = this.secondRoomType;
        //   params.SecondRoomCode = this.secondRoomOcc;
        //   params.SecondRoomAdults = this.secondRoomNumberOfAdults;
        //   params.SecondRoomChildren = this.secondRoomNumberOfChildren;
        // }

        /* uncomment this */
        hxBooking(this.selectedProduct.Code, params).then(response => {
          const { API_Reply: apiResponse } = response;

          if (!apiResponse.Error) {
            this.bookingDetails = apiResponse;
            this.sendSuccessEmail();

            this.showBookingComplete = true;
            localStorage.removeItem(
              `bookingParams${btoa(window.location.host)}`
            );
          } else {
            /* payment accepted but something went wrong in HX */
            this.sendErrorEmail(
              apiResponse.Error.Code,
              apiResponse.Error.Message
            );
            this.showError = true;
          }
        });
      } else {
        this.sendEmail2("Payment Error", error);
      }
    },
    sendErrorEmail(errorType = "Booking Error", message = "Payment failed") {
      window.emailjs.send(
        this.emailConfig.serviceID,
        this.emailTemplates.ERROR,
        {
          from_name: "Hotels24Seven",
          errorType: errorType,
          errorMessage: message,
          hotel: this.selectedProduct.Name,
          roomOne: this.bookingParams.roomName,
          price: this.selectedProduct.Price,
          arriveDate: this.bookingParams.departDate,
          parkingDays: this.selectedProduct.ParkingDays,
          hxCode: this.selectedProduct.Code,
          amendable: this.selectedProduct.noncancellable_nonrefundable
            ? "Yes"
            : "No",
          ...this.user,
          ...this.bookingParams
        },
        this.emailConfig.publicKey
      );
    },
    sendSuccessEmail() {
      const response = this.bookingDetails;
      const itinerary =
        response && response.Itinerary ? response.Itinerary : {};
      const booking = response && response.Booking ? response.Booking : {};
      const clientDetails =
        response && response.ClientDetails ? response.ClientDetails : {};
      // const carDetails = response && response.CarDetails ? response.CarDetails : {};
      const hotel = response && response.Hotel ? response.Hotel : {};

      const cancellationMessage =
        this.productData.noncancellable_nonrefundable !== true
          ? "<strong>CANCEL FREE OF CHARGE</strong> up to 24 hours prior to your date of stay."
          : "<strong>This booking is offered at a reduced rate but is non-amendable and non-refundable.</strong>";

      const emailObj = {
        from_name: "Hotels24Seven",
        hotelName: this.selectedProduct.Name,
        packageName:
          itinerary && itinerary.Name
            ? itinerary.Name
            : this.selectedProduct.Name,
        airport: this.bookingParams.airportCode,
        reference: booking.BookingRef,
        email: clientDetails.Email,
        title: clientDetails.Title,
        firstname: clientDetails.Initial,
        surname: clientDetails.Surname,
        stayDate: moment(this.bookingParams.departDate).format("ddd, Do MMM"),
        departDate: moment(this.bookingParams.returnDate).format("ddd, Do MMM"),
        roomType: this.bookingParams.roomCode,
        numAdults: this.bookingParams.adults,
        numChildren: this.bookingParams.children,
        totalPrice:
          itinerary && itinerary.TotalPrice
            ? itinerary.TotalPrice.toFixed(2)
            : this.selectedProduct.NonDiscPrice.toFixed(2),
        parking:
          itinerary && +itinerary.ParkingDays
            ? this.parkingMarkup(itinerary)
            : "",
        extraRoom:
          hotel && hotel.length > 1 ? this.roomTwoMarkUp(hotel[1]) : "",
        address: this.productData?.address,
        hotelPhone: this.productData?.telephone,
        hotelArrival:
          itinerary && +itinerary.ParkingDays > 0
            ? this.productData.hotel_arrival_parking
            : this.productData.hotel_arrival,
        hotelDepart:
          itinerary && +itinerary.ParkingDays > 0
            ? this.productData.hotel_depart_parking
            : this.productData.hotel_depart,
        hotelReturn:
          itinerary && +itinerary.ParkingDays > 0 ? this.onReturnMarkup() : "",
        directions: this.productData?.directions,
        cancellationMessage: cancellationMessage,
        hotelPhoto: this.productData?.logo,
        information: this.productData?.information
      };

      window.emailjs.send(
        this.emailConfig.serviceID,
        this.emailTemplates.SUCCESS,
        emailObj,
        this.emailConfig.publicKey
      );
    },
    roomTwoMarkUp(room) {
      return (
        `<table style="width: 100%; max-width: 600px; line-height: 30px; font-size: 13px; border="0" cellspacing="0" cellpadding="0">` +
        "<tr>" +
        '<td style="width: 50%;">Second Room:</td>' +
        '<td style="width: 50%;"><strong>' +
        room.RoomCode +
        " (" +
        room.Adults +
        " Adults, " +
        room.Children +
        " Children)</strong></td>" +
        "</tr>" +
        "</table>"
      );
    },
    parkingMarkup(itinerary) {
      return (
        '<table style="width: 100%; max-width: 600px; line-height: 30px; font-size: 13px; border="0" cellspacing="0" cellpadding="0">' +
        "<tr>" +
        '<td style="width: 50%;">Parking Package:</td>' +
        '<td style="width: 50%;"><strong>' +
        (itinerary.Name ? itinerary.Name : this.selectedProduct.Name) +
        "</strong></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="width: 50%;">Parking From:</td>' +
        '<td style="width: 50%;"><strong>' +
        moment(this.bookingParams.departDate).format("ddd, Do MMM") +
        "</strong></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="width: 50%;"">Parking To:</td>' +
        '<td style="width: 50%;""><strong>' +
        moment(this.bookingParams.departDate)
          .add(
            this.bookingParams.ParkingDays
              ? this.bookingParams.ParkingDays
              : this.bookingParams.stayingDays,
            "day"
          )
          .format("ddd, Do MMM") +
        "</strong></td>" +
        "</tr>" +
        "</table>"
      );
    },
    onReturnMarkup() {
      if (parseInt(this.bookingDetails.Itinerary.ParkingDays) >= 1) {
        return (
          '<table style="width: 100%; max-width: 600px; line-height: 30px; font-size: 13px; border="0" cellspacing="0" cellpadding="0">' +
          "<tr>" +
          '<td style="font-size: 16px; padding-top: 10px; padding-bottom: 10px; line-height: 18px;"><strong>On Return</strong></td>' +
          "</tr>" +
          "<tr>" +
          '<td style="font-size: 13px; padding-bottom: 30px; line-height: 18px;">' +
          this.productData.hotel_return_parking +
          "</td>" +
          "</tr>" +
          "</table>"
        );
      }
    },
    dummydata(form = "user") {
      if (this.$app.env === "production") {
        return {};
      } else {
        const dummy = {
          user: {
            title: "Mr",
            firstname: "David",
            surname: "Marsh",
            phone: "+44123123113",
            email: "dave@hotels24seven.com"
          },
          address: {
            address1: "Waterhall Country House",
            address2: "Prestwood Lane",
            town: "Ifield Wood",
            county: "West Susxsex",
            postcode: "RH11 0LA",
            country: "GB"
          },
          card: {
            CardNumber: "4137355039315383",
            ExpiryMonth: "02",
            ExpiryYear: "2022",
            Cvv2: "123",
            CardType: "Visa"
          }
        };
        return dummy[form];
      }
    }
  },
  async created() {
    await getProduct(this.selectedProduct.Code).then(response => {
      this.productData = response.API_Reply.Product.reduce(data => data);
    });
  },
  mounted() {
    /* used this to verify endpoint to avoid live booking when testing */
    // if(confirm(`${this.$app.apiHost}\nProceed to test booking.`)) {
    //   this.bookInHX();
    // }
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="sass">
.hidden
  display: none
</style>
