export default {
  methods: {
    is_object(value) {
      return typeof value === "object";
    },
    is_boolean(value) {
      return typeof value === "boolean";
    },
    is_undefined(value) {
      return typeof value === "undefined";
    },
    is_empty(value) {
      return !!Object.keys(value).length;
    },
    formValidator(formFields) {
      let self = this;
      let errors = {};
      let validator = {
        required(key, value, errMsg) {
          // convert boolean value to a string
          if (self.is_boolean(value)) {
            value = value ? "boolean" : "";
          }

          if (!value || value.trim().length === 0) {
            if (!self.is_object(errors[key])) {
              // eslint-disable-next-line no-return-assign
              return (errors[key] = errMsg);
            }
          }
        },
        email(key, value, errMsg) {
          if (!/\S+@\S+\.\S+/.test(value)) {
            if (self.is_object(errors[key]) || self.is_undefined(errors[key])) {
              // eslint-disable-next-line no-return-assign
              return (errors[key] = errMsg);
            }
          }
        },
        numeric(key, value, errMsg) {
          if (isNaN(parseInt(value))) {
            if (self.is_object(errors[key]) || self.is_undefined(errors[key])) {
              // eslint-disable-next-line no-return-assign
              return (errors[key] = errMsg);
            }
          }
        },
        boolean(key, value, errMsg) {
          if (typeof value !== "boolean") {
            if (self.is_object(errors[key]) || self.is_undefined(errors[key])) {
              // eslint-disable-next-line no-return-assign
              return (errors[key] = errMsg);
            }
          }
        }
      };

      Object.keys(formFields).forEach(key => {
        let validationKeys = formFields[key]["validations"];

        Object.keys(validationKeys).forEach(valField => {
          if (validator[valField]) {
            validator[valField](
              key,
              formFields[key]["value"],
              validationKeys[valField]
            );
          }
        });
      });

      return errors;
    }
  }
};
