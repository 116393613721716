<template>
  <div class="home">
    <book-now />
  </div>
</template>

<script>
import bookNow from "@/components/bookNow.vue";
export default {
  components: {
    bookNow
  },
  data() {
    return {
      clientID:
        "Aa40-vYXIsQqKyEqtYstW6KxduOyamrfJUflt6hLz7Tg4SNl1SQyAu6hSXcl9ANEMpJj1ehVi3q-SrQ_"
    };
  },
  methods: {
    checkData() {
      if (!this.$store.getters.getAirport) {
        this.$router.push({ name: "Home" });
      }
    },
    insertPaypalScript() {
      var script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://www.paypal.com/sdk/js?client-id=" + this.clientID
      );
      script.setAttribute("defer", true);
      document.head.appendChild(script);
    }
  },
  mounted() {
    this.checkData();
  }
};
</script>

<style lang="sass">
@import "../assets/sass/global.sass"
</style>
